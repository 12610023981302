<template>
    <div class="page">
        <div class="page__content">
            <div class="title__wrapper">
                <h1 class="mb-10 mr-24">{{ $filters.getTranslationByKey(currentPage.title) }}</h1>
                <p>{{ currentPage.date }}</p>
            </div>
            <div class="page__body">
                <div v-html="$filters.getTranslationByKey(currentPage.content)"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    components: {
        
    },
    data() {
        return {
  
        }
    },
    computed: {
        ...mapGetters('page', ['currentPage']),
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
    .page {
        &__content {
            max-width: 500px;
            margin: 0 auto;
        }

        &__title-wrapper {

        }
    }
</style>